<template>
    <div class="modal__input-area">
        <div v-if="loading" class="modal__loading">
            <filling-circle :size="42" color="#057DCD"/>
        </div>
        <div v-else>
            <image-upload :control="!editable" :id="id" :subId="subId" @get-image="setImage" :image="categoryDetails.image"/>

            <edit-box :id="id" :subId="subId" :editable="editable" name="name" requestKey="name" :defaultValue="categoryDetails.name"  type="text"/>
            <edit-box :id="id" :subId="subId" :editable="editable" name="description"  requestKey="description" :defaultValue="categoryDetails.description" type="description"/>

            <div class="modal__buttons-container" v-if="editable">
                <button class="modal__button modal__button--delete" @click="openDelete">delete</button>
            </div>
        </div>
	</div>
</template>
<script>
import ImageUpload from '@/components/Modals/ModalInputs/ImageUpload.vue'
import EditBox from '@/components/Modals/ModalInputs/EditBox.vue'
import FillingCircle from '@/components/Loaders/FillingCircle.vue'
export default {
    components:{EditBox,FillingCircle,ImageUpload },
	data(){
        return {
            loading:true,
        }
    },
    computed:{
        subId(){
            return this.$route.params.subId
        },
        id(){
            return this.$route.params.id
        },

        categoryDetails(){
            return this.$store.state.products.categoryDetails
        },

        editable(){
            let userRole = this.$store.state.user.user_role
            return userRole ? userRole.can_manage_products : true
        }
    },
	methods:{
        openDelete(){
            this.$emit('delete')
        },
        setImage(image){
           this.image = image

        },
	},
	mounted(){
        
        this.loading = true
        this.$store.dispatch({
            type:'products/loadCategoryInstance',
            id: this.subId,
        }).then( ()=>{
            this.loading = false
        }).catch(error=>{
            console.log(error)
            this.loading = false
            this.$router.go(-1)
        })
    }
    
}
</script>