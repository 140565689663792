<template>
    <div class="modal-container">
		<div class="modal">
			<div class="modal__title">
				<h1>Edit Product Category</h1> 
				<button class="modal__close" @click="closeModal">
					<img class="modal__close-image" src="@/assets/images/close.svg" alt="">
				</button>
			</div>
			<delete-category v-if="deleteModal"/> 
			<product-category-edit :id="id" :subId="subId" v-else  @delete="deleteModal=true"/>	
		</div>
	</div>
</template>
<script>
import ProductCategoryEdit from '@/components/Modals/ModalInputs/ProductCategoryEdit.vue'
import DeleteCategory from '@/components/Modals/EditModals/Delete.vue'



export default {
    props:{
        name: String,
		
    },
	components:{ ProductCategoryEdit, DeleteCategory },
	data(){
		return {
			deleteModal: false,
		}
	},
	computed:{ 
		title(){
			return this.deleteModal ? "Delete category" : "Category Details"
		},
		subId(){
			return this.$route.params.subId
		},
		id(){
			return this.$route.params.id
		},
			
	},
	methods:{
		closeModal(){
			this.$router.go(-1)
		}
	},
}
</script>
